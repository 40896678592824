<template>
  <div class="containe-fluid">
    <p v-if="loading" style="text-align:center">Loading content...</p>
    <router-view v-else />
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      data: null,
      loading: true,
    };
  },
  mounted() {
    axios.get("https://www.theshadingcompany.ae/wp-json/wp/v2/showroom_display").then((response) => {
      console.log(response.data);
      this.data = response.data;
      this.loading = false;
    });

    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = "https://www.theshadingcompany.ae/wp-content/uploads/2019/12/cropped-favicon-96x96-32x32.png";
  },
};
</script>
